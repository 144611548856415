import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'QuestionsCreate',
    data() {
        return {
            question: {
                title: null
            },
            fileAudio: null
        }
    },
    components: {
        adminLayout
    },
    methods: {
        createQuestion() {
            const self = this
            let formData = new FormData()

            if(self.question.title) formData.append('title', self.question.title)
            if(self.fileAudio) formData.append('audio', self.fileAudio)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/questions',
                method: 'POST',
                data: formData
            }).then(function() {
                self.$router.push({ name: 'admin.questions' })

                self.notifySuccess()
            })
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        }
    }
}